import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import parseObjectToUrlParams from "../../../utils/parseObjectToUrlParams";
import TopFilter from "../common/TopFilter/TopFilter";
import TrackHistory from "../Dashboard/TrackHistory/TrackHistory";
import { ReportsStyled } from "./styled";
import AuthStore from "../../../stores/auth/auth.store";
import { t } from "i18next";
import { cn } from "../../../utils";
import moment from "moment";
import { DatePicker, Slider } from "antd";

const axios = require("axios");

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
}

function getSavingPotential(item: MixtureBehavior, displayTime: string) {
  if (displayTime === "day") {
    if (item.slump_deviation_day < 0) {
      return 0;
    }
    return Math.round(
      (item.slump_deviation_day / 25) * item.total_cube_day * 0.5
    );
  }

  if (displayTime === "week") {
    if (item.slump_deviation_week < 0) {
      return 0;
    }
    return Math.round(
      (item.slump_deviation_week / 25) * item.total_cube_week * 0.5
    );
  }

  if (displayTime === "month") {
    if (item.slump_deviation_month < 0) {
      return 0;
    }
    return Math.round(
      (item.slump_deviation_month / 25) * item.total_cube_month * 0.5
    );
  }
}
const dayColumns = [
  { title: t("Mixture name and code"), dataIndex: "mixture_name" },
  { title: t("Code-Id"), dataIndex: "mixture_code" },
  {
    title: t("mm for correction"),
    dataIndex: "slump_deviation_day",
    render: (value: string, record: any) => {
      return value && value !== null ? value : "-";
    },
  },
  {
    title: t("Cubic Meter"),
    dataIndex: "total_cube_day",
    render: (value: string, record: any) => {
      return value && value !== null ? value : "-";
    },
  },
  {
    title: t("Deliveries"),
    dataIndex: "number_of_deliveries_day",
    render: (value: string, record: any) => {
      return value && value !== null ? value : "-";
    },
  },
  {
    title: t("Cost Savings Potential"),
    dataIndex: "flowCommand",
    render: (type: string, record: any) => {
      return getSavingPotential(record, "day") + " $";
    },
  },
];

const weekColumns = [
  { title: t("Mixture name and code"), dataIndex: "mixture_name" },
  { title: t("Code-Id"), dataIndex: "mixture_code" },
  {
    title: t("mm for correction"),
    dataIndex: "slump_deviation_week",
    render: (value: string, record: any) => {
      return value && value !== null ? value : "-";
    },
  },
  {
    title: t("Cubic Meter"),
    dataIndex: "total_cube_week",
    render: (value: string, record: any) => {
      return value && value !== null ? value : "-";
    },
  },
  {
    title: t("Deliveries"),
    dataIndex: "number_of_deliveries_week",
    render: (value: string, record: any) => {
      return value && value !== null ? value : "-";
    },
  },
  {
    title: t("Cost Savings Potential"),
    dataIndex: "flowCommand",
    render: (type: string, record: MixtureBehavior) => {
      return getSavingPotential(record, "week") + " $";
    },
  },
];
const monthColumns = [
  { title: t("Mixture name and code"), dataIndex: "mixture_name" },
  { title: t("Code-Id"), dataIndex: "mixture_code" },
  {
    title: t("mm for correction"),
    dataIndex: "slump_deviation_month",
    render: (value: string, record: any) => {
      return value && value !== null ? value : "-";
    },
  },
  {
    title: t("Cubic Meter"),
    dataIndex: "total_cube_month",
    render: (value: string, record: any) => {
      return value && value !== null ? value : "-";
    },
  },
  {
    title: t("Deliveries"),
    dataIndex: "number_of_deliveries_month",
    render: (value: string, record: any) => {
      return value && value !== null ? value : "-";
    },
  },
  {
    title: t("Cost Savings Potential"),
    dataIndex: "flowCommand",
    render: (type: string, record: MixtureBehavior) => {
      return getSavingPotential(record, "month") + " $";
    },
  },
];

function getDeviationRange(
  correction: string
): [number | null, number | null] | undefined {
  switch (correction) {
    case "-25mm":
      return [null, -25];
    case "-10mm":
      return [-24, -10];
    case "-9<mm<9":
      return [-9, 9];
    case "+10mm":
      return [10, 24];
    case "+25mm":
      return [25, null];
    default:
      return;
  }
}

type MixtureBehavior = {
  createdAt: string;
  date: string;
  factory_id: number;
  group_id: number;
  id: number;
  mixture_code: string;
  mixture_name: string;
  number_of_deliveries_day: number;
  number_of_deliveries_month: number;
  number_of_deliveries_week: number;
  slump_deviation_day: number;
  slump_deviation_month: number;
  slump_deviation_week: number;
  total_cube_day: number;
  total_cube_month: number;
  total_cube_week: number;
  updatedAt: string;
  report_status: string;
};

function isDeviationInRange(
  item: MixtureBehavior,
  displayTime: string,
  range: [number | null, number | null]
) {
  if (displayTime === "day") {
    return (
      (range[0] === null || item.slump_deviation_day >= range[0]) &&
      (range[1] === null || item.slump_deviation_day <= range[1])
    );
  }

  if (displayTime === "week") {
    return (
      (range[0] === null || item.slump_deviation_week >= range[0]) &&
      (range[1] === null || item.slump_deviation_week <= range[1])
    );
  }

  if (displayTime === "month") {
    return (
      (range[0] === null || item.slump_deviation_month >= range[0]) &&
      (range[1] === null || item.slump_deviation_month <= range[1])
    );
  }
}

async function fetchReports(
  group_id: number | string,
  filters: Record<string, any>
) {
  const rsp = await axios.get(
    `${process.env.REACT_APP_API_URL}/mixtures_behavior/calculate/${group_id}?` +
      parseObjectToUrlParams(filters)
  );

  return rsp.data.data;
}

@inject("authStore")
@observer
class Reports extends React.PureComponent<Props, any> {
  @observable data: MixtureBehavior[] = [];
  @observable sensor_number: string = "";
  @observable who: string;
  @observable type: string;
  @observable flow: any;
  @observable displayTime: "day" | "week" | "month" = "week";
  @observable savings = 0;
  @observable viewType: "chart" | "table" = "chart";
  @observable currentDate = moment().format("DD/MM/YYYY");
  @observable deviation:
    | "All"
    | "-25mm"
    | "-10mm"
    | "-9<mm<9"
    | "+10mm"
    | "+25mm" = "All";
  @observable filters: Record<string, any> = {};

  onCorrectionChange = (
    deviation: "All" | "-25mm" | "-10mm" | "-9<mm<9" | "+10mm" | "+25mm"
  ) => {
    this.deviation = deviation;
  };

  @observable tableHeader = [
    { label: t("id"), key: "id" },
    { label: t("sensorNumber"), key: "sensor_id" },
    { label: t("name"), key: "name" },
    { label: t("role"), key: "role" },

    { label: t("type"), key: "type" },
    { label: t("kamot"), key: "flow_command" },
    { label: t("realAdd"), key: "flow" },
    { label: t("date"), key: "time_stamp" },
  ];

  @observable group_id = this.props.authStore!.getUserGroipID();

  componentDidMount = async () => {
    await this.fetchData();
  };

  fetchData = async () => {
    try {
      // const current_date = this.data[0]?.date;
      // this.currentDate = moment(current_date).format("DD/MM/YYYY/");
      this.filters.date = this.currentDate;

      this.data = await fetchReports(this.group_id, this.filters);
      console.log({ da: this.data });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  onFilterChange = async (filterName: string, filterValue: any) => {
    switch (filterName) {
      case "factory_id":
        this.filters.factory_id = filterValue;
        break;

      case "loading_concreate_type":
        this.filters.mixture_code = filterValue;
        break;

      case "date":
        this.currentDate = filterValue;
        break;

      default:
        break;
    }
    this.fetchData();
  };

  @observable columns = [
    {
      title: t("Mixture name and code"),
      dataIndex: "mixture_name",
    },
    {
      title: t("Code-Id"),
      dataIndex: "mixture_code",
    },
    {
      title: t("mm for correction"),
      dataIndex: "slump_deviation_month",
      render: (value: string, record: any) => {
        return value && value !== null ? value : "-";
      },
    },
    {
      title: t("Cubic Meter"),
      dataIndex: "total_cube_week",
    },
    {
      title: t("Deliveries"),
      dataIndex: "createdAt",
      render: (type: string, record: any) => {
        return "0";
      },
    },
    {
      title: t("Cost Savings Potential"),
      dataIndex: "flowCommand",
      render: (type: string, record: any) => {
        let result: any = /[^_]*$/.exec(record!.flow_command)[0];
        result = result / 100;
        if (isNaN(result) || record.who === 1) {
          result = 0;
        }

        return result;
      },
    },
  ];

  @observable items = [
    { size: "All", name: "All" },
    { size: "-25mm", name: "-25mm" },
    { size: "-10mm", name: "-10mm" },
    { size: "-9<mm<9", name: "-9<mm<9" },
    { size: "+10mm", name: "+10mm" },
    { size: "+25mm", name: "+25mm" },
  ] as const;

  @observable time_selector = [
    { size: "day", name: "Day" },
    { size: "week", name: "Week" },
    { size: "month", name: "Month" },
  ] as const;

  getTotalSavings = (data: any[], displayTime: string): number => {
    return data
      .filter((item) => {
        const range = getDeviationRange(this.deviation);
        if (!range) return true;
        return isDeviationInRange(item, displayTime, range);
      })
      .filter((item) => {
        const saving = getSavingPotential(item, displayTime);
        return saving >= this.savings;
      })
      .reduce(
        (total, item) => total + getSavingPotential(item, displayTime),
        0
      );
  };

  render() {
    return (
      <ReportsStyled>
        <div className="content-page">
          <div className="flex page-title">
            <TopFilter
              customFilters={
                <DatePicker
                  className="mb-6 rounded-[4px] border border-[#9CA3AF]"
                  onChange={(value) =>
                    this.onFilterChange(
                      "date",
                      moment(value).format("DD/MM/YYYY")
                    )
                  }
                  defaultValue={moment(this.currentDate, "DD/MM/YYYY")}
                  format="DD/MM/YYYY"
                />
              }
              background={false}
              filters={["factory"]}
              onFilterChange={this.onFilterChange}
            />
            <div className="overflow-hidden pt-6">
              {this.time_selector.map((item) => (
                <button
                  key={item.size}
                  className={cn(
                    "rounded-none bg-transparent text-black border-b-2 text-lg font-bold hover:text-slate-500 hover:border-slate-500 hover:border-b-4",
                    this.displayTime === item.size &&
                      "text-main-orange border-b-4 border-main-orange hover:text-main-orange hover:border-main-orange"
                  )}
                  onClick={() => {
                    if (this.displayTime === item.size) return;
                    this.onFilterChange("date-range", [
                      moment().subtract(1, item.size).format("YYYY-MM-DD"),
                      moment().format("YYYY-MM-DD"),
                    ]);
                    this.displayTime = item.size;
                  }}
                >
                  {t(item.name)}
                </button>
              ))}
            </div>
          </div>

          <div className="title">Actions for Repair</div>

          <div className="flex justify-between items-center">
            <div className="flex flex-row items-center gap-14 p-2.5">
              <div className="flex flex-row items-center">
                {this.items.map((item) => (
                  <button
                    key={item.size}
                    className={cn(
                      "rounded-none py-px bg-transparent text-black border-b-2 border-black text-lg font-bold hover:text-slate-500 hover:border-slate-500 hover:border-b-4",
                      {
                        "text-main-orange border-b-4 border-main-orange hover:text-main-orange hover:border-main-orange py-0":
                          this.deviation === item.size,
                      }
                    )}
                    onClick={() => this.onCorrectionChange(item.size)}
                  >
                    {t(item.name)}
                  </button>
                ))}
              </div>
              <div className="w-full">
                <Slider
                  className="w-full min-w-44"
                  railStyle={{ backgroundColor: "#374151" }}
                  onChange={(value: number) => {
                    this.savings = value;
                  }}
                  marks={{
                    0: {
                      label: <span className="text-sm font-medium">0</span>,
                    },
                    50: {
                      label: <span className="text-sm font-medium">50</span>,
                    },
                    100: {
                      label: <span className="text-sm font-medium">100</span>,
                    },
                    150: {
                      label: <span className="text-sm font-medium">150</span>,
                    },
                  }}
                  min={0}
                  max={150}
                  tooltip={{
                    formatter: (value: number) => (
                      <div className="place-items-center ">
                        <div>Estimated Saving</div>
                        <div className="border px-2 py-1">{value}$</div>
                      </div>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="flex gap5 align-center min-w-16">
              {this.viewType === "chart" ? (
                <img src="/images/chart-active.svg" alt="chart active" />
              ) : (
                <img
                  src="/images/chart-inactive.svg"
                  alt="chart"
                  className="pointer"
                  onClick={() => (this.viewType = "chart")}
                />
              )}
              <span
                style={{
                  backgroundColor: "#9CA3AF",
                  width: "1px",
                  height: "20px",
                }}
              ></span>
              {this.viewType === "table" ? (
                <img src="/images/table-active.svg" alt="table active" />
              ) : (
                <img
                  src="/images/table-inactive.svg"
                  className="pointer"
                  alt="table"
                  onClick={() => (this.viewType = "table")}
                />
              )}
            </div>
          </div>

          <div className="box">
            {this.displayTime === "day" && (
              <TrackHistory
                trackHistory={this.data
                  .filter((item) => {
                    const range = getDeviationRange(this.deviation);
                    if (!range) return true;
                    return isDeviationInRange(item, this.displayTime, range);
                  })
                  .filter((item) => {
                    const saving = getSavingPotential(item, this.displayTime);
                    return saving >= this.savings;
                  })}
                columns={dayColumns}
              />
            )}
            {this.displayTime === "week" && (
              <TrackHistory
                trackHistory={this.data
                  .filter((item) => {
                    const range = getDeviationRange(this.deviation);
                    if (!range) return true;
                    return isDeviationInRange(item, this.displayTime, range);
                  })
                  .filter((item) => {
                    const saving = getSavingPotential(item, this.displayTime);
                    return saving >= this.savings;
                  })}
                columns={weekColumns}
              />
            )}
            {this.displayTime === "month" && (
              <TrackHistory
                trackHistory={this.data
                  .filter((item) => {
                    const range = getDeviationRange(this.deviation);
                    if (!range) return true;
                    return isDeviationInRange(item, this.displayTime, range);
                  })
                  .filter((item) => {
                    const saving = getSavingPotential(item, this.displayTime);
                    return saving >= this.savings;
                  })}
                columns={monthColumns}
              />
            )}
          </div>

          <div className="text-lg flex  justify-end gap-2 my-6">
            <span>{t("Total estimated savings")}:</span>
            <span className="font-semibold">
              {this.getTotalSavings(this.data, this.displayTime)} $
            </span>
          </div>
        </div>
      </ReportsStyled>
    );
  }
}
export default withRouter(Reports);
