import { Badge, Table } from "antd";
import moment from "moment";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { TrackHistoryStyled } from "./styled";
import { t } from "i18next";
import { ColumnsType } from "antd/lib/table";

interface Props extends RouteComponentProps<any> {
  trackHistory: any;
  columns?: any[];
  rowKey?: (record: any) => string;
  footer?: (data: readonly any[]) => React.ReactNode;
}

type TableProps<T> = {
  columns: ColumnsType<T>;
  rowKey?: (record: T) => string;
  footer?: (data: readonly T[]) => React.ReactNode;
  data: T[];
};

function getIdOfRecord(record: Record<string, unknown>) {
  if (
    "id" in record &&
    record.id &&
    (typeof record.id === "string" || typeof record.id === "number")
  ) {
    return record.id;
  }
  if (
    "_id" in record &&
    record._id &&
    (typeof record._id === "string" || typeof record._id === "number")
  ) {
    return record._id;
  }

  return undefined;
}

export function CustomTable<T extends Record<string, unknown> = {}>(
  props: TableProps<T>
) {
  return (
    <TrackHistoryStyled>
      <Table
        rowKey={(rec) => props.rowKey?.(rec) || getIdOfRecord(rec)}
        dataSource={props.data}
        columns={props.columns}
        footer={props.footer}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </TrackHistoryStyled>
  );
}

class TrackHistory extends React.PureComponent<Props, any> {
  componentDidMount = async () => {
    let threeMinAgo = moment().subtract(3, "minutes").toDate().getTime();
    this.props.columns &&
      this.props.columns.forEach((record, index) => {
        // Check if the conditions are met
        if (record.time_stamp < threeMinAgo && record.status === "2") {
          // If conditions are met, update the status to 12
          this.props.columns[index].status = "12";
        }
      });

    try {
    } catch (error) {
      console.error(error);
    }
  };
  columns = this.props.columns ?? [
    {
      title: t("id"),
      dataIndex: "id",
    },

    {
      title: t("act"),
      dataIndex: "createdAt",
      render: (type: string, record: any) => {
        if (record.who === 0) {
          return record.flow_command === "STOP_FLOW" ? (
            <Badge className="flex" status="warning">
              {t("stop")}
            </Badge>
          ) : (
            <Badge className="flex" status="success">
              {t("start")}
            </Badge>
          );
        } else {
          return (
            <Badge className="flex" status="error">
              {t("manual")}
            </Badge>
          );
        }
      },
    },

    {
      title: t("status"),
      dataIndex: "status",
      render: (type: string, record: any) => {
        // 5 | 9 - pcb not responding for request
        // 3- pcb return Nack
        // 12- Android crash
        // 11  - busy
        // 13 manual
        // 1 - waiting for Andorid response
        // 2 | 4 -
        // 8 - stopped by request

        let threeMinAgo = moment().subtract(3, "minutes").toDate().getTime();

        if (record.flow_command === "STOP_FLOW") {
          return "-";
        }

        if (record.time_stamp < threeMinAgo && record.status === "2") {
          return t("actionDidentFinihed");
        }

        switch (record.status) {
          case "0":
            return t("startAction");
            break;

          case "2":
            return t("flowStart");
            break;

          case "4":
            return t("flowStart");
            break;

          case "6":
            return t("flowStart");
            break;

          case "8":
            return t("stoppedByAdmin");
            break;

          case "10":
            return t("finished");
            break;

          case "5":
            return t("actionDidentFinihed");
            break;

          case "9":
            return t("actionDidentFinihed");
            break;

          case "11":
            return t("busy");
            break;
          case "12":
            return t("actionDidentFinihed");
            break;

          case "13":
            return t("addedByDriver");
            break;

          case "14":
            return t("driverFinshed");
            break;
        }
      },
    },

    {
      title: t("addon"),
      dataIndex: "createdAt",
      render: (type: string, record: any) => {
        return record.type === "water" ? t("water") : t("mosaf");
      },
    },
    {
      title: t("kamot"),
      dataIndex: "flowCommand",
      render: (type: string, record: any) => {
        let result: any = /[^_]*$/.exec(record!.flow_command)[0];
        result = result / 100;
        if (isNaN(result) || record.who === 1) {
          result = 0;
        }

        if (record.flow_command === "STOP_FLOW") {
          return "-";
        }

        return result;
      },
    },
    {
      title: t("realAdd"),
      dataIndex: "flow",
      render: (type: string, record: any) => {
        // if (isNaN(result)) {
        //   result = "-";
        // }

        if (record.flow_command === "STOP_FLOW") {
          return "-";
        }
        return record.flow / 100;
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
      render: (type: string, record: any) => {
        return record.who === 0 ? record.name : "-";
      },
    },
    {
      title: t("role"),
      dataIndex: "role",
      render: (type: string, record: any) => {
        if (record.who === 1) {
          return t("driver");
        }
        switch (record.role) {
          case "admin":
            return t("admin");
          case "driver":
            return t("driver");

          case "loader":
            return t("factory");

          default:
            break;
        }
      },
    },
    {
      title: t("date"),
      dataIndex: "time_stamp",
      render: (type: string, record: any) => {
        return moment(record.time_stamp).format("DD/MM/YY , HH:mm");
      },
    },
  ];

  render() {
    return (
      <TrackHistoryStyled>
        <Table
          rowKey={(rec) => rec.id || rec._id || this.props.rowKey?.(rec)}
          dataSource={this.props.trackHistory.slice().reverse()}
          columns={this.columns}
          footer={this.props.footer}
          pagination={{
            hideOnSinglePage: true,
          }}
        />
      </TrackHistoryStyled>
    );
  }
}
export default withRouter(TrackHistory);
