import { inject, observer } from "mobx-react";
import React, { useContext } from "react";
import {
  Redirect,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import PrivateRoute from "../../components/Auth/PrivateRoute";
import PublicOnlyRoute from "../../components/Auth/PublicOnlyRoute";
import Calibration from "../../components/Calibration/Calibration";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SignIn from "../../components/SignIn/SignIn";
import authStore from "../../stores/auth/auth.store";
import Concrete from "../app/Concrete/Concrete";
import ConcreteDelivery from "../app/Concrete/ConcreteDelivery/ConcreteDelivery";
import PageDisplay from "../app/Concrete/ConcreteDelivery/PageDisplay/PageDisplay";
import ConcretePrediction from "../app/Concrete/ConcretePrediction/ConcretePrediction";
import ConcreteReports from "../app/Concrete/ConcreteReports/ConcreteReports";
import ConcreteTesting from "../app/Concrete/ConcreteTesting/ConcreteTesting";

import ConcreteView from "../app/Concrete/ConcreteView/ConcreteView";
import FactoryDisplay from "../app/Concrete/FactoryDisplay/FactoryDisplay";
import Dashboard from "../app/Dashboard/Dashboard";
import Sidebar from "../app/Dashboard/Sidebar/Sidebar";
import Messurment from "../app/Messurment/Messurment";
import Profile from "../app/Profile/Profile";
import ReadData from "../app/ReadData/ReadData";
import ImportData from "../app/ReadData/ImportData";
import Reports from "../app/Reports/Reports";
import Settings from "../app/Settings/Settings";
import SensorsData from "../app/ReadData/SensorsData/SensorsData";
import SensorsStatus from "../app/ReadData/SensorsStatus/SensorsStatus";
import ConcreteUnusual from "../app/Concrete/ConcreteUnusual/ConcreteUnusual";
import Messages from "../app/ReadData/Messages/Messages";
import WaterAddons from "../app/WaterAddons/WaterAddons";
import ConcreteSlump from "../app/Concrete/ConcreteSlump/ConcreteSlump";
import SensorConnections from "../app/ReadData/SensorConnections";
import ConcreteUnusualMain from "../app/Concrete/ConcreteUnusual/ConcreteUnusualMain";
import ReadDataFactories from "../app/ReadData/ReadDataFactories";
import MessurmentAll from "../app/Messurment/MessurmentAll";
import { observable } from "mobx";
import { RawMaterialsChangesPage } from "../app/MaterialsChange";
import { MixtureControlPage } from "../app/MixtureControl";
import SettingsUnusualMain from "../app/Concrete/ConcreteUnusual/SettingsUnusualMain";
import SlumpChanges from "../app/ReadData/SlumpChanges";
import ReportsOpen from "../app/Reports/ReportsOpen";
import ReportsClose from "../app/Reports/ReportsClose";

interface Props extends RouteComponentProps<any> {
  authStore?: authStore;
}

@inject("authStore")
@observer
class PagesRouter extends React.PureComponent<Props, any> {
  @observable showBar = true;

  render() {
    return (
      <>
        <Switch>
          <PublicOnlyRoute
            path={"/login"}
            component={SignIn}
            redirectTo="/login"
          />

          <PrivateRoute
            path={"/messurment/"}
            component={Messurment}
            loginPath="/login/"
          />

          <PrivateRoute
            path={"/measurement/"}
            component={MessurmentAll}
            loginPath="/login/"
          />

          <PublicOnlyRoute
            path="/concrete/testing"
            component={ConcreteTesting}
          />

          <div className="flex mobile-flex">
            {this.showBar ? (
              <Sidebar closeBar={() => (this.showBar = !this.showBar)} />
            ) : (
              <svg
                onClick={() => (this.showBar = !this.showBar)}
                className="clos-bar single"
                width="50"
                height="51"
                viewBox="0 0 50 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="0.584961"
                  width="50"
                  height="50"
                  rx="5"
                  fill="#FF6700"
                />
                <path
                  d="M28.3334 18.9185L21.6667 25.5851L28.3334 32.2518"
                  stroke="#2D2D2D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            <div
              className={`content-wrapper flex1 ${
                this.showBar ? "" : "bar-close"
              }`}
            >
              <PrivateRoute
                path="/dashboard"
                component={WaterAddons}
                loginPath="/login"
              />
              <PrivateRoute
                path="/addons"
                component={WaterAddons}
                loginPath="/login"
              />
              <PrivateRoute
                path="/reports"
                component={Reports}
                loginPath="/login"
              />
              <PrivateRoute
                path="/reports/open"
                component={ReportsOpen}
                loginPath="/login"
              />

              <PrivateRoute
                path="/reports/close"
                component={ReportsClose}
                loginPath="/login"
              />
              <PrivateRoute
                path="/concrete/view"
                component={ConcreteView}
                loginPath="/login"
              />
              <PrivateRoute
                path="/concrete/predictions"
                component={ConcretePrediction}
                loginPath="/login"
              />
              <PrivateRoute
                path="/concrete/delivery"
                component={<ConcreteDelivery />}
                loginPath="/login"
              />
              <PrivateRoute
                path="/concrete/delivery/view"
                component={PageDisplay}
                loginPath="/login"
              />

              <PrivateRoute
                path="/concrete/unusual"
                component={ConcreteUnusualMain}
                loginPath="/login"
              />

              <PrivateRoute
                path="/concrete/slump"
                component={ConcreteSlump}
                loginPath="/login"
              />

              <PrivateRoute
                path="/concrete/factory/"
                component={FactoryDisplay}
                loginPath="/login"
              />
              <PrivateRoute
                path="/concrete/dashboard"
                component={Concrete}
                loginPath="/login"
              />
              <PrivateRoute
                path="/concrete/calibration"
                component={Calibration}
                loginPath="/login"
              />
              <PrivateRoute
                path="/sensors/status"
                component={ReadData}
                loginPath="/login"
              />

              <PrivateRoute
                path="/sensors/status/factories"
                component={ReadDataFactories}
                loginPath="/login"
              />

              <PrivateRoute
                path="/sensors/slump/changes"
                component={SlumpChanges}
                loginPath="/login"
              />

              <PrivateRoute
                path="/sensors/status/raw_materials_changes"
                component={RawMaterialsChangesPage}
                loginPath="/login"
              />
              <PrivateRoute
                path="/sensors/status/mixture_control"
                component={MixtureControlPage}
                loginPath="/login"
              />

              <PrivateRoute
                path="/sensors/sync"
                component={ImportData}
                loginPath="/login"
              />
              <PrivateRoute
                path="/sensors/connections"
                component={SensorConnections}
                loginPath="/login"
              />
              <PrivateRoute
                path="/real-data"
                component={ReadData}
                loginPath="/login"
              />
              <PrivateRoute
                path="/read-data"
                component={ReadData}
                loginPath="/login"
              />
              <PrivateRoute
                path={"/settings/"}
                component={Settings}
                loginPath="/login/"
              />
              <PrivateRoute
                path="/settings/unusual"
                component={SettingsUnusualMain}
                loginPath="/login"
              />
              <PrivateRoute
                path={"/profile/"}
                component={Profile}
                loginPath="/login/"
              />
              <PrivateRoute
                path="/"
                component={<Redirect to="/addons" />}
                loginPath="/login"
              />
            </div>
          </div>
        </Switch>
      </>
    );
  }
}
export default withRouter(PagesRouter);
