import { action, observable, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import parseObjectToUrlParams from "../../../utils/parseObjectToUrlParams";
import TopFilter from "../common/TopFilter/TopFilter";
import TrackHistory, {
  CustomTable,
} from "../Dashboard/TrackHistory/TrackHistory";
import { ReportsStyled } from "./styled";
import { t } from "i18next";
import AuthStore from "../../../stores/auth/auth.store";
import axios from "axios";
import { ColumnType } from "antd/lib/table";
import { Modal, Slider } from "antd";
import { useTranslation } from "react-i18next";

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
}

async function fetchOpenReport(
  group_id: number | string,
  filters: Record<string, any>
): Promise<MixtureBehavior[]> {
  const rsp = await axios.get(
    `${process.env.REACT_APP_API_URL}/mixtures_behavior/open-report/${group_id}?` +
      parseObjectToUrlParams(filters)
  );

  return rsp.data.data;
}

async function updateReport(
  report_id: number,
  values: { report_status?: string; action_status?: string }
) {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/mixtures_behavior/update-report/${report_id}`,
      values
    );
    return true;
  } catch (error) {
    console.error(error);
  }

  return false;
}
async function updateReports(values: {
  report_status?: string;
  action_status?: string;
  ids: number[];
}) {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/mixtures_behavior/update-reports`,
      values
    );
    return true;
  } catch (error) {
    console.error(error);
  }

  return false;
}

type MixtureBehavior = {
  createdAt: string;
  date: string;
  factory_id: number;
  group_id: number;
  id: number;
  mixture_code: string;
  mixture_name: string;
  number_of_deliveries_day: number;
  number_of_deliveries_month: number;
  number_of_deliveries_week: number;
  slump_deviation_day: number;
  slump_deviation_month: number;
  slump_deviation_week: number;
  total_cube_day: number;
  total_cube_month: number;
  total_cube_week: number;
  total_cube_month_company: number;
  updatedAt: string;
  status: string;
  report_status?: string;
  action_status?: string;
};

function getSavingPotential(item: MixtureBehavior, displayTime: string) {
  if (displayTime === "day") {
    if (item.slump_deviation_day < 0) {
      return 0;
    }
    return Math.round(
      (item.slump_deviation_day / 25) * item.total_cube_day * 0.5
    );
  }

  if (displayTime === "week") {
    if (item.slump_deviation_week < 0) {
      return 0;
    }
    return Math.round(
      (item.slump_deviation_week / 25) * item.total_cube_month_company * 0.5
    );
  }

  if (displayTime === "month") {
    if (item.slump_deviation_month < 0) {
      return 0;
    }
    return Math.round(
      (item.slump_deviation_month / 25) * item.total_cube_month * 0.5
    );
  }
}

const columns = [
  { title: t("Mixture name and code"), dataIndex: "mixture_name" },
  { title: t("Code-Id"), dataIndex: "mixture_code" },
  { title: t("mm Slump for Correction"), dataIndex: "slump_deviation_week" },
];

function ClosePopup(props: { open: boolean; onClose: () => void }) {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      open={props.open}
      footer={null}
      closeIcon={
        <span className="flex items-center justify-center w-full h-full">
          <img
            src="/images/actions/close.svg"
            alt="close"
            onClick={props.onClose}
            className="w-10 h-10"
          />
        </span>
      }
    >
      <div className="flex flex-col gap-10 items-center" dir={i18n.dir()}>
        <div className="flex flex-col gap-5 items-center">
          <div className="text-lg font-semibold">
            The report has not been saved!{" "}
          </div>
          <span className="text-4xl font-bold text-center ">
            Unsaved
            <span className="text-[#E02424]"> changes will be lost </span>
            and cannot be recovered.
          </span>
        </div>
        <span className="flex gap-5">
          <button
            className="bg-main-orange text-white py-2.5 px-5 font-bold text-sm rounded-[5px]"
            onClick={props.onClose}
          >
            Return To Report
          </button>
          <button
            className="text-main-orange border-2 border-main-orange bg-white py-2.5 px-5 font-bold text-sm rounded-[5px]"
            onClick={props.onClose}
          >
            Exit
          </button>
        </span>
      </div>
    </Modal>
  );
}

function SaveAndExitPopup(props: {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  acceptedReports: MixtureBehavior[];
}) {
  const { i18n } = useTranslation();
  return (
    <Modal
      open={props.open}
      footer={null}
      width={700}
      closeIcon={
        <span className="flex items-center justify-center w-full h-full">
          <img
            src="/images/actions/close.svg"
            alt="close"
            onClick={props.onClose}
            className="w-10 h-10"
          />
        </span>
      }
    >
      <div className="flex flex-col gap-10 items-center" dir={i18n.dir()}>
        <div className="flex flex-col gap-5 items-center">
          <div className="text-lg font-semibold">
            The weekly report has been successfully closed!
          </div>
          <span className="text-4xl font-bold text-center ">
            <span className="text-[#0E9F6E]"> Remember to implement </span>
            the following corrections in the batching plant
          </span>
        </div>
        <div className="grid grid-cols-4">
          <span className="text-lg font-semibold col-span-2">Mix Design</span>
          <span className="text-lg font-semibold">Code</span>
          <span className="text-lg font-semibold">mm Slump for Correction</span>
          {props.acceptedReports.map((item, i) => {
            return (
              <>
                <span className="col-span-2">{item.mixture_name}</span>
                <span>{item.mixture_code}</span>
                <span>{item.slump_deviation_week}</span>
                <span className="col-span-3 border-b-2 border-[#E5E5E5] mt-5"></span>
              </>
            );
          })}
        </div>
        <span className="flex gap-5">
          <button
            className="text-main-orange border-2 border-main-orange bg-white py-2.5 px-5 font-bold text-sm rounded-[5px] flex items-center gap-2.5"
            onClick={props.onClose}
          >
            <img
              src="/images/actions/open-link.svg"
              alt="share"
              className="h-4"
            />
            <span>Share</span>
          </button>
          <button
            className="bg-main-orange text-white py-2.5 px-5 font-bold text-sm rounded-[5px]"
            onClick={props.onConfirm}
          >
            Confirm
          </button>
        </span>
      </div>
    </Modal>
  );
}

@inject("authStore")
@observer
class ReportsOpen extends React.PureComponent<Props, any> {
  @observable filters: Record<string, any> = {};
  @observable group_id = this.props.authStore!.getUserGroipID();
  @observable top5: MixtureBehavior[] = [];
  @observable rest: MixtureBehavior[] = [];
  @observable data: MixtureBehavior[] = [];
  @observable isClosable: boolean = false;
  @observable showClosePopup: boolean = false;
  @observable showSaveAndExitPopup: boolean = false;

  @observable totalCubeMonthCompany: number = 0;

  fecthData = async () => {
    try {
      const search = this.props.location.search;
      const date = new URLSearchParams(search).get("date");

      if (date) {
        this.filters.date = date;
      }
      const data = await fetchOpenReport(this.group_id, this.filters);
      const sorted = data.sort((a, b) => {
        return getSavingPotential(b, "week") - getSavingPotential(a, "week");
      });
      this.data = sorted;
      const top5 = sorted.slice(0, 5);
      const rest = sorted.slice(5);

      if (
        top5.every(
          (item) =>
            (item.action_status === "accepted" ||
              item.action_status === "rejected") &&
            item.report_status !== "closed"
        )
      ) {
        this.isClosable = true;
      }
      this.top5 = top5;
      this.rest = rest;
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount = () => {
    this.fecthData();
  };

  onFilterChange = async (filterName: string, filterValue: any) => {
    switch (filterName) {
      case "factory_id":
        this.filters.factory_id = filterValue;
        break;
    }
  };

  changeMonthlyCube = async (value: number, record: any) => {
    console.log({ value });
    this.totalCubeMonthCompany = value;

    const data = {
      total_cube_month_company: value,
    };
    const rsp = await axios.post(
      `${process.env.REACT_APP_API_URL}/mixtures_behavior/reports-grouped/updat_cubic/${record.id}`,
      data
    );
    this.fecthData();

    //post to update total_cube_month_company
  };

  columnstop5: ColumnType<MixtureBehavior>[] = [
    {
      title: t("Record ID"),
      render: (_: string, record: MixtureBehavior) => {
        return record.id;
      },
      className: "text-start",
      width: 150,
    },
    {
      title: t("Mixture name and code"),
      dataIndex: "mixture_name",
      width: 350,
      className: "text-start",
    },
    {
      title: t("Code-Id"),
      dataIndex: "mixture_code",
      className: "text-start",
      width: 150,
    },
    {
      title: t("mm Slump for Correction"),
      dataIndex: "slump_deviation_week",
      className: "text-start",
      width: 180,
    },
    {
      title: t("Total Cubes"),
      render: (_: string, record: MixtureBehavior) => {
        this.totalCubeMonthCompany = record.total_cube_month_company;
        return (
          <div className="flex gap-2 items-center">
            <Slider
              min={0}
              max={5000} // Adjust based on expected cube range
              value={this.totalCubeMonthCompany} // Bind to observable state
              style={{ flex: 1, marginRight: 8 }}
              onChange={(value: number) =>
                this.changeMonthlyCube(value, record)
              } // Update observable and record
            />
            <span>{record.total_cube_month_company}</span>
          </div>
        );
      },
      className: "text-start",
      width: 200,
    },
    {
      title: t("Estimated savings"),
      render: (_: string, record: MixtureBehavior) => {
        return getSavingPotential(record, "week") + " $";
      },
      className: "text-start",
      width: 150,
    },
    {
      title: t("Action"),
      className: "text-start",
      render: (_: string, record: MixtureBehavior) => {
        if (record.action_status === "accepted") {
          return (
            <div
              className="text-green-500 text-start"
              onClick={() => {
                updateReport(record.id, { action_status: "" }).then(() => {
                  this.fecthData();
                });
              }}
            >
              Accepted
            </div>
          );
        }

        if (record.action_status === "rejected") {
          return (
            <div
              className="text-red-600 text-start"
              onClick={() => {
                updateReport(record.id, { action_status: "" }).then(() => {
                  this.fecthData();
                });
              }}
            >
              Ignored
            </div>
          );
        }
        return (
          <div className="flex gap-2.5">
            <button
              className="text-[#057A55] border-2 border-[#057A55] bg-white py-2.5 px-5 font-bold text-sm rounded-[5px]"
              onClick={() => {
                updateReport(record.id, { action_status: "accepted" }).then(
                  () => {
                    this.fecthData();
                  }
                );
              }}
            >
              Accept Correction
            </button>
            <button
              className="text-red-600 border-2 border-red-600 bg-white py-2.5 px-5 font-bold text-sm rounded-[5px]"
              onClick={() => {
                updateReport(record.id, { action_status: "rejected" }).then(
                  () => {
                    this.fecthData();
                  }
                );
              }}
            >
              Ignore Correction
            </button>
          </div>
        );
      },
    },
  ];

  render() {
    return (
      <ReportsStyled>
        <div className="content-page">
          {/* <TopFilter
            filters={["factory"]}
            onFilterChange={this.onFilterChange}
          /> */}

          <div className="title mb-4">Repair Actions</div>
          <CustomTable
            data={this.top5}
            columns={this.columnstop5}
            footer={(data) => {
              return (
                <div className="flex justify-center">
                  <div className="text-lg flex gap-2 ">
                    <span>{t("Total estimated savings")}:</span>
                    <span className="font-semibold">
                      {data.reduce((acc, item) => {
                        return acc + getSavingPotential(item, "week");
                      }, 0)}{" "}
                      $
                    </span>
                  </div>
                </div>
              );
            }}
          />

          <div className="title my-4 ">Mix designs status</div>
          <CustomTable data={this.rest} columns={columns} />
        </div>
        {this.isClosable && (
          <div className="sticky bottom-0 bg-[#FF8A4C] h-[70px] flex items-center justify-center">
            <div className="flex gap-10 items-center">
              <span className="text-white">
                Please choose what you’d like to do before exiting
              </span>
              <span className="flex gap-5">
                <button
                  className="text-red-600 border-2 border-red-600 bg-white py-2.5 px-5 font-bold text-sm rounded-[5px]"
                  onClick={() => {
                    this.showClosePopup = true;
                  }}
                >
                  Close Without Saving
                </button>
                <button
                  className="bg-[#057A55] text-white py-2.5 px-5 font-bold text-sm rounded-[5px]"
                  onClick={async () => {
                    this.showSaveAndExitPopup = true;
                  }}
                >
                  Save and Exit
                </button>
              </span>
            </div>
          </div>
        )}
        <ClosePopup
          open={this.showClosePopup}
          onClose={() => {
            this.showClosePopup = false;
          }}
        />
        <SaveAndExitPopup
          open={this.showSaveAndExitPopup}
          onClose={() => {
            this.showSaveAndExitPopup = false;
            this.isClosable = true;
          }}
          onConfirm={() => {
            updateReports({
              ids: this.data.map((item) => item.id),
              report_status: "closed",
            }).then(async () => {
              this.fecthData();
              this.showSaveAndExitPopup = false;
              this.isClosable = false;
            });
          }}
          acceptedReports={this.top5.filter(
            (item) => item.action_status === "accepted"
          )}
        />
      </ReportsStyled>
    );
  }
}
export default withRouter(ReportsOpen);
